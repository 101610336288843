<template>
  <section class="hero flex flex-col lg:flex-row">
    <picture
      v-if="hero && hero.media.type === 'image'"
      class="w-full lg:w-[44%]"
      :class="[
        hero.firstItemMobile === 'media' ? 'order-1' : 'order-2',
        hero.firstItemDesktop === 'media' ? 'lg:order-1' : 'lg:order-2',
      ]"
    >
      <source media="(max-width: 768px)" :srcset="hero.media.path.mobile" type="image/jpeg" />
      <source media="(min-width: 769px)" :srcset="hero.media.path.desktop" type="image/jpeg" />
      <img
        :src="hero.media.path.desktop"
        :alt="hero.media.alt"
        class="block w-full"
        width="100%"
        height="100%"
        :class="hero.media.maxHeight ? `max-h-[${hero.media.maxHeight}]` : ''"
        loading="lazy"
      />
    </picture>
    <video
      v-if="hero && hero.media.type === 'video'"
      autoplay
      muted
      loop
      playsInline
      class="w-full lg:w-[44%]"
      width="100%"
      height="100%"
      :poster="hero.media.thumb"
      :class="[
        hero.firstItemMobile === 'media' ? 'order-1' : 'order-2',
        hero.firstItemDesktop === 'media' ? 'lg:order-1' : 'lg:order-2',
      ]"
      :src="isMobile ? hero.media.path.mobile : hero.media.path.desktop"
    ></video>

    <div
      v-if="hero && hero.media.type === 'slot'"
      class="block w-full"
      :class="[
        hero.firstItemMobile === 'media' ? 'order-1' : 'order-2',
        hero.firstItemDesktop === 'media' ? 'lg:order-1' : 'lg:order-2',
      ]"
      :style="`background-color: ${hero.text.backgroundColor}`"
    >
      <slot></slot>
    </div>

    <div
      v-if="hero"
      :class="[
        hero.firstItemMobile === 'media' ? 'order-2' : 'order-1',
        hero.firstItemDesktop === 'media' ? 'lg:order-2' : 'lg:order-1',
      ]"
      class="flex w-full flex-col px-6 max-lg:order-1 lg:w-[56%] lg:px-16"
      :style="`background-color: ${hero.text.backgroundColor}`"
    >
      <div
        class="flex w-full flex-col max-lg:py-14 lg:my-auto"
        :class="hero.firstItem === 'media' ? '' : '1xl:ml-auto'"
        :style="`color: ${hero.text.color}`"
      >
        <hgroup>
          <h2
            v-if="hero.text.title"
            class="font-bold leading-tight"
            :class="[hero.text.subtitle != '' ? 'text-6xl lg:text-8xl' : 'text-5xl lg:text-7xl']"
            v-html="hero.text.title"
          />
          <h3 v-if="hero.text.subtitle" class="mt-3 text-4xl leading-tight lg:text-5xl" v-html="hero.text.subtitle" />
          <h4
            v-if="hero.text.description"
            class="mt-8 text-2xl leading-tight lg:text-4xl"
            v-html="hero.text.description"
          />
        </hgroup>
        <Button
          v-if="hero.cta"
          :width="hero.cta.width"
          @click="redirect(hero.cta)"
          class="max-lg:mx-auto max-md:!w-full"
        >
          {{ hero.cta.label }}
        </Button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";

import Button from "@/components/Buttons/Button/v2/Button.vue";
import useIsMobile from "@/hooks/useIsMobile";
import Tracker from "@/utils/Tracker/Tracker";
import { trackButton } from "@/utils/analitycsHelper/button";

const { isMobile } = useIsMobile();

const props = defineProps({
  hero: {
    type: Object,
    default: () => ({}),
  },
  content: {
    type: Object,
    default: () => ({}),
  },
});

const redirect = async (cta) => {
  const nameEvent = 'button_'+cta.label.replace(/ /g, "-").toLowerCase()
  Tracker.getInstance().track(nameEvent, trackButton(cta.label+" Home"));
  window.open(cta.url, cta.target);
};

const hero = computed(() => props.hero);
</script>

<style lang="scss" scoped></style>
